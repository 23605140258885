import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as myGlobals from "./globals";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private APIURL = myGlobals.base_api_url;
  constructor(private httpClient: HttpClient) { }

  get CheckLoginStatus() {
    if (localStorage.getItem("isLoggedin")) {
      return true;
    } else {
      return false;
    }
  }

  adminLogin(userDetails) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.httpClient.post(this.APIURL + 'BO/user/authenticate', userDetails, { headers });
  }

  forgotPassword(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.httpClient.post(this.APIURL + 'BO/user/forgotpassword', body, { headers });
  }

  StoreUserDetailsLocally(Data) {
    localStorage.setItem("AT", Data.token);
  }


}
