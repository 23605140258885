import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from './globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  private APIURL = myGlobals.base_api_url;
  private token = localStorage.getItem('AT');
  //  / private AccessToken = sessionStorage.getItem('UserToken');
  constructor(private httpClient: HttpClient) { }

  getMyDetails() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json").set("Authorization", this.token);
    return this.httpClient.get(this.APIURL + 'BO/user/profile', { headers });
  }

  //Get List with Paginated
  getCategory(pageNo) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.get(this.APIURL + 'FO/template/listcategory?start=' + pageNo, { headers })
  }


  //Get List with Paginated
  searchTemplate(body) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.post(this.APIURL + 'FO/template/search', body, { headers })
  }

  getHomeContent() {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.get(this.APIURL + 'FO/template/home', { headers })
  }

  //Get List with Paginated
  getSubCategory(pageNo) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.get(this.APIURL + 'FO/template/listsubcategory?start=' + pageNo, { headers })
  }


  parseHTML(url) {
    return this.httpClient.get(url)
  }

  getTemplateDetails(id) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.get(this.APIURL + 'FO/template/view?id=' + id, { headers })
  }

  downloadTemplate(type, body, id) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.post(this.APIURL + 'FO/template/download?format=' + type + '&template_id=' + id, body, { headers })
  }

  login(body) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.post(this.APIURL + 'FO/template/login', body, { headers })
  }

  sendMail(toMail, type, body, id) {
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    return this.httpClient.post(this.APIURL + '/FO/template/sendmail?format=' + type + '&email=' + toMail + '&template_id=' + id, body, { headers })
  }


}