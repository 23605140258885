import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./auth.guard";
import { AuthGuardCanLoadService } from "./auth-guard-can-load.service";
const routes: Routes = [
  {
    path: "",
    loadChildren: "./homepage/homepage.module#HomepageModule"
  },
  {
    path: "document",
    loadChildren: "./document/document.module#DocumentModule",
    // canLoad: [AuthGuardCanLoadService]
  },
  // {
  //   path: "**",
  //   loadChildren: "./homepage/homepage.module#HomepageModule"
  // }
  {
    path: "myaccount",
    loadChildren: "./myaccount/myaccount.module#MyaccountModule",
    // canLoad: [AuthGuardCanLoadService]
  },
  {
    path: "faq",
    loadChildren: "./faq/faq.module#FaqModule",
    // canLoad: [AuthGuardCanLoadService]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
