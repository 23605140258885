import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../data-sharing.service';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userDeatils: any;
  categoryLists: any = [];
  subCategoryLists: any = [];
  userName: string;


  constructor(private dataSharingService: DataSharingService, private http: HttpService, private router: Router) {
    if (localStorage.getItem('userName')) {
      this.userName = localStorage.getItem('userName');
    }
    this.dataSharingService.userDetails.subscribe(value => {
      if (value) {
        this.userName = value.prenom;
      }
    });
  }

  ngOnInit() {
    this.getAllCategory(0);
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  getAllCategory(added) {
    this.http.getCategory(added).subscribe(
      (res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          this.categoryLists.push(res.data[i]);
        }
        this.getSubCategory(0);
      },
      err => { });
  }

  getSubCategory(added) {
    this.http.getSubCategory(added).subscribe(
      (res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          this.subCategoryLists.push(res.data[i]);
        }
        for (let j = 0; j < this.categoryLists.length; j++) {
          this.categoryLists[j].child = [];
        }
        for (let i = 0; i < this.subCategoryLists.length; i++) {
          for (let j = 0; j < this.categoryLists.length; j++) {
            if (this.subCategoryLists[i].parent == this.categoryLists[j].id) {
              this.categoryLists[j].child.push(this.subCategoryLists[i]);
            }
          }
          this.dataSharingService.categoryAllList.next(this.categoryLists);
        }
      },
      err => { });
  }

}
